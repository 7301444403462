import React, { FC } from 'react';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
    User
} from "@nextui-org/react";
import SettingsPopover from "../SettingsPopover/SettingsPopover";
import {useAuth0} from "@auth0/auth0-react";


interface UserAvatarProps {}

const UserAvatar: FC<UserAvatarProps> = () => {

    const {user} = useAuth0();

    return (
        <Popover showArrow placement="bottom">
            <PopoverTrigger>
                <User
                    as="button"
                    name="Arnold"
                    description="Free Tier"
                    className="transition-transform"
                    avatarProps={{
                        src: user?.picture
                    }}
                />
            </PopoverTrigger>
            <PopoverContent className="p-1">
                <SettingsPopover/>
            </PopoverContent>
        </Popover>
    );
}

export default UserAvatar;
