import React, {FC, useContext} from 'react';
import {ThemeContext} from "../../providers/Provider";
import {Moon, Sun} from "@phosphor-icons/react";
import {Divider, Switch} from '@nextui-org/react';

interface ThemeSwitcherProps {}

const ThemeSwitcher: FC<ThemeSwitcherProps> = () => {
    const {theme, toggleTheme} = useContext(ThemeContext);
    return (
        <div className={"mt-2 flex-row flex flex-wrap w-full"}>
            <div className={"flex-col w-4/5 self-center text-medium tracking-tight"}>
                Enable darkmode?
            </div>
            <div className={"flex-col w-1/5"}>
                <Switch isSelected={(theme == "dark")} onChange={toggleTheme}>
                    {theme === "dark" ? <Moon size={20}/> : <Sun size={20}/>}
                </Switch>
            </div>
            <Divider className={"mt-4"}/>
        </div>
    );
}

export default ThemeSwitcher;
