import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalContent, ModalHeader, useDisclosure} from "@nextui-org/react";
import AddByUrl from "../AddByUrl/AddByUrl";
import ApproachModalContent from "../ApproachModalContent/ApproachModalContent";

interface ApproachModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ApproachModal: FC<ApproachModalProps> = (props) => {

    const {onOpen, onClose, isOpen} = useDisclosure();
    const [modalContent, setModalContent] = useState("method");

    useEffect(() => {
        if(!isOpen && props.isOpen) {
            onOpen();
            setModalContent("method");
            props.onClose();
        }
    }, [props.isOpen]);

    const setAi = () => {
        setModalContent("ai");
    };

    return (
        <>
            <Modal backdrop={"blur"}
                   isOpen={isOpen}
                   size={"4xl"}
                   onClose={onClose}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        How would you like to add your new recipe?
                    </ModalHeader>
                    <ModalBody className={"flex flex-row pb-8 w-full"}>
                        {modalContent === "ai" ? <AddByUrl /> : ""}
                        {modalContent === "method" ? <ApproachModalContent setModal={setAi} /> : ""}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ApproachModal;
