import React, {FC, useEffect, useState} from 'react';
import {Plus, XCircle} from "@phosphor-icons/react";
import {IngredientDto} from "../../models/IngredientDto";
import {Recipe} from "../../models/Recipe";
import {Ingredient} from "../../models/Ingredient";


interface FormIngredientsProps {
    recipe?: Recipe
    set: any
}

const FormIngredients: FC<FormIngredientsProps> = (props) => {

    const [ingredients, setIngredients] = useState( [] as IngredientDto[]);

    useEffect(() => {
        setIngredients(props?.recipe?.ingredients ?? [] as IngredientDto[]);
    }, [props.recipe]);

    const onIngredientsChange = (event: any, id: number) => {
        const updatedIngredients = ingredients?.map((ingredient) => {
            if (ingredient.id === id) {
                return {
                    ...ingredient,
                    name: event.target.value,
                } as IngredientDto;
            }
            return ingredient;
        });
        setIngredients(updatedIngredients);
        props.set(updatedIngredients);
    };

    const addEmptyIngredient = () => {
        const newIngredient = {
            id: ingredients.length + 1,
            name: '',
        };
        setIngredients([...ingredients, newIngredient]);
        props.set([...ingredients, newIngredient]);
    };

    const removeIngredient = (event: any, id: number) => {
        const updatedIngredients = ingredients?.filter(ingredient => ingredient.id !== id);
        setIngredients(updatedIngredients);
    };

    return(<>
        <div className="flex flex-col ml-[1px] w-full mt-4">
        </div>

        <div className={"flex justify-content-start"}>
            <label className="mb-2 mt-4">
                <span><b>Ingredients</b></span>

                <button type="button"
                        onClick={addEmptyIngredient}
                        className="w-[2rem] h-[2rem] flex-shrink-0 ml-4
                                            inline-flex justify-center items-center
                                            text-sm rounded-md
                                            border border-transparent bg-gray-300 text-gray-800 hover:text-gray-200 hover:bg-gray-500
                                            disabled:opacity-50 disabled:pointer-events-none">
                    <Plus className="flex-shrink-0 size-4" size={32}/>
                </button>
            </label>
        </div>

        <div className={"flex flex-col w-full gap-4"}>
            <ul>
                {ingredients.length === 0 ? <span className={"text-white flex text-sm"}>
                                        0 ingredients
                                    </span> : ""}
                {ingredients?.map((ingredient) => (
                    <div className="flex w-1/2 max-sm:w-full" key={ingredient.id}>
                        <input type="text"
                               className="mt-2 py-2 px-4 w-full border-gray-200 shadow-sm bg-content4
                                                   rounded-s-lg text-sm focus:z-10 focus:border-blue-500
                                                   focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                               key={ingredient.id}
                               value={ingredient.name}
                               onChange={(event) => onIngredientsChange(event, ingredient.id ?? 0)}
                        />
                        <button type="button"
                                onClick={(event) => removeIngredient(event, ingredient.id ?? 0)}
                                className="mt-2 w-[2.4rem] h-[2.4rem] flex-shrink-0 inline-flex
                                                    justify-center items-center gap-x-2 text-sm font-semibold
                                                    rounded-e-md border border-transparent bg-gray-300 text-gray-800
                                                    hover:text-gray-200 hover:bg-gray-500 disabled:opacity-50
                                                    disabled:pointer-events-none dark:focus:outline-none
                                                     dark:focus:ring-1 dark:focus:ring-gray-600">
                            <XCircle className="flex-shrink-0 size-4" size={32}/>
                        </button>
                    </div>
                ))}
            </ul>
        </div>
    </>);
}
export default FormIngredients;
