import React, {FC, useEffect, useState} from 'react';
import {Button, Input} from "@nextui-org/react";
import ReactQuill from "react-quill";
import FormIngredients from "../FormIngredients/FormIngredients";
import FormImages from "../FormImages/FormImages";
import {Recipe} from "../../models/Recipe";
import {IngredientDto} from "../../models/IngredientDto";
import {RecipeDto} from "../../models/RecipeDto";
import RecipeService from "../../services/recipeService";
import useAuthToken from "../../hooks/useAuthToken";
import {useNavigate} from "react-router";
import ControlledInput from "../ControllerInput/ControlledInput";

interface RecipeEditFormProps {
    recipe: Recipe | undefined
}

const RecipeEditForm: FC<RecipeEditFormProps> = (props) => {

    const baseImageUrl: string = process.env.REACT_APP_S3_URL ?? "";

    const [recipe, setRecipe] = useState<Recipe>(props.recipe ?? {} as Recipe);

    const [ingredients, setIngredients] = useState([] as IngredientDto[]);
    const [fileFormData, setFileFormData] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const token = useAuthToken();
    const navigate = useNavigate();

    const [recipeText, setRecipeText] = useState(props.recipe?.recipe ?? "");

    async function fetchImages(r: Recipe) {
        const imageFiles = [];
        for (const image of r.images) {
            try {
                const response = await fetch(`${baseImageUrl}${image.name}`, {
                    mode: 'no-cors',
                });
                const blob = await response.blob();
                const file = new File([blob], image.name);
                imageFiles.push(file);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        }
        return imageFiles;
    }

    useEffect(() => {
        if (props.recipe?.images != null) {
            fetchImages(props.recipe).then((imageFiles) => {
                setFileFormData([...fileFormData, ...imageFiles]);
            });
        }
        setRecipe(props.recipe ?? {} as Recipe);
        setRecipeText(props.recipe?.recipe ?? "");
        setIngredients(props.recipe?.ingredients ?? []);
    }, [props.recipe]);

    const packFiles = (files: File[]) => {
        const data = new FormData();
        files.forEach((file, i) => {
            data.append(`Images`, file, file.name);
        });
        return data;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage(null); // reset error message on each submit attempt

        const formElements = event.currentTarget.elements as any;
        let editRecipe: RecipeDto = {
            id: props.recipe?.id ?? 0,
            userId: props.recipe?.userId ?? "",
            source: "user-created",
            title: formElements.title?.value,
            category: formElements.category?.value,
            course: formElements.course?.value,
            recipe: recipeText,
            servings: formElements.servings?.value,
            nutrition: {
                id: props.recipe?.nutrition.id ?? 0,
                fat: formElements.fat?.value,
                protein: formElements.protein?.value,
                carbs: formElements.carbs?.value,
                calories: formElements.calories?.value,
            },
            times: {
                id: props.recipe?.times.id ?? 0,
                prepTime: formElements.prepTime?.value ?? "",
                cookTime: formElements.cookTime?.value ?? "",
                totalTime: formElements.totalTime?.value ?? "",
            },
            images: [],
            ingredients: ingredients
        };

        const formData = new FormData();
        formData.append('Recipe', JSON.stringify(editRecipe));

        // Pack files to avoid duplicate code
        const fileData = packFiles(fileFormData);
        fileData.forEach((value, key) => formData.append(key, value));

        const authToken = await token.getToken();
        if (!authToken) return;

        const service = new RecipeService();

        try {

            const response = await service.putRecipe(props.recipe?.id ?? 0, formData, authToken);
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (response.status === 200) {
                const localRecipe = await response.json() as Recipe;
                navigate(`/recipe/${localRecipe.id}`);
            } else if (response.status === 400) {
                setErrorMessage("There was an issue with your submission. Please check your inputs.");
            } else if (response.status === 500) {
                setErrorMessage("Server error. Please try again later.");
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
        } catch (error) {
            setErrorMessage("Failed to submit. Please check your internet connection.");
        }
    };

    return (
        <form onSubmit={handleSubmit} className={"w-3/5 max-lg:w-full"}>

            {errorMessage && (
                <span className="flex mb-4 text-danger">
                    {errorMessage}
                </span>
            )}

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Describe the recipe in a short sentence</b></span>
            </div>

            <div className="flex w-full mt-2">
                <ControlledInput
                    type="text"
                    name="title"
                    value={props.recipe?.title}
                    label="Recipe title"
                    placeholder="Create a title for your new recipe"
                />
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Categorize your recipe</b></span>
            </div>

            <div className="flex max-sm:flex-col w-full gap-4 mt-2">
                <ControlledInput type="text" name="category" value={props.recipe?.category} label="Category" placeholder="Recipe category"/>
                <ControlledInput type="text" name="course" value={props.recipe?.course} label="Course" placeholder="e.g., Main Course"/>
                <ControlledInput type="text" name="servings" value={props.recipe?.servings} label="Servings" placeholder="Amount of servings"/>
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Timings</b></span>
                <span>Create timings for your recipe (optional)</span>
            </div>

            <div className="flex max-sm:flex-col w-full gap-4 mt-2">
                <ControlledInput type="text" name="cookTime" value={props.recipe?.times?.cookTime} label="Cook time" placeholder="1 hour 30 minutes"/>
                <ControlledInput type="text" name="prepTime" value={props.recipe?.times?.prepTime} label="Prep time" placeholder="30 minutes"/>
                <ControlledInput type="text" name="totalTime" value={props.recipe?.times?.totalTime} label="Total Time" placeholder="2 hours"/>
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Nutrition</b></span>
                <p>Fill out nutritional information (optional)</p>
            </div>

            <div className="flex max-sm:flex-col w-full gap-4 mt-2">
                <ControlledInput type="text" value={props.recipe?.nutrition?.carbs} name="carbs" label="Carbs" placeholder="Carbohydrates"/>
                <ControlledInput type="text" value={props.recipe?.nutrition?.fat} name="fat" label="Fat" placeholder="Fat content"/>
                <ControlledInput type="text" value={props.recipe?.nutrition?.protein} name="protein" label="Protein" placeholder="Protein content"/>
                <ControlledInput type="text" value={props.recipe?.nutrition?.calories} name="calories" label="Calories" placeholder="Calories"/>
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Directions</b></span>
                <p>Describe your recipe in detail.</p>
            </div>

            <div className="flex w-full gap-4 mt-4">
                <ReactQuill
                    value={recipeText}
                    onChange={setRecipeText}
                    theme="snow"
                    className="border-none dark:bg-content2 dark:text-default-500 ring-2 ring-gray-100 dark:ring-content3 w-full text-black min-h-[300px] rounded-xl"
                    modules={{toolbar: true}}
                />
            </div>

            <FormIngredients recipe={props.recipe} set={setIngredients}/>
            <FormImages setFileData={setFileFormData}/>

            <Button
                color="primary"
                className="mt-4"
                variant="bordered"
                type="submit"
            >
                Update recipe
            </Button>
        </form>
    );
}

export default RecipeEditForm;
