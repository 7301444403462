import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import useAuthToken from "../hooks/useAuthToken";
import Menu from "../components/Menu/Menu";
import {Avatar, Button, Card, CardBody, CardHeader, Divider, Input, Listbox, ListboxItem} from "@nextui-org/react";
import {Plus, PlusCircle, UsersThree, XCircle} from "@phosphor-icons/react";
import ReactQuill from "react-quill";
import './theme.css';
import {IngredientDto} from "../models/IngredientDto";
import FormIngredients from "../components/FormIngredients/FormIngredients";
import FormImages from "../components/FormImages/FormImages";
import RecipeForm from "../components/RecipeForm/RecipeForm";

interface CreateRecipeProps {
}

const CreateRecipe: FC<CreateRecipeProps> = (props) => {

    const params = useParams();
    const authToken = useAuthToken();
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    return (
        <div>
            <div className={"container mx-auto"}>
                <div className={""}>
                    <Card className="my-4">
                        <CardHeader className="flex p-8">
                            <div>
                                <h1 className="text-4xl my-4 inline-flex gap-3 tracking-tight font-bold items-end">
                                    <PlusCircle weight="duotone" /> Create Recipe
                                </h1>
                                <span className={"flex w-full"}>
                                    Create your own recipes using our recipe builder.
                                </span>
                            </div>
                        </CardHeader>
                        <Divider/>
                        <CardHeader className={"flex-col flex w-full items-start p-8"}>
                            <RecipeForm />
                        </CardHeader>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default CreateRecipe;
