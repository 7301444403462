import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from '@nextui-org/react';
import React, {FC, useEffect, useRef, useState} from 'react';
import {Trash} from "@phosphor-icons/react";
import {useAuth0} from "@auth0/auth0-react";
import RecipeService from "../../services/recipeService";
import {Recipe} from "../../models/Recipe";
import {useNavigate} from "react-router";


interface RecipeDeleteModalProps {
    recipe: Recipe
}

const RecipeDeleteModal: FC<RecipeDeleteModalProps> = (props) => {

    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {getAccessTokenSilently} = useAuth0();
    const [token, setToken] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        getAccessTokenSilently().then(result => {
            setToken(result);
        });
    }, [props.recipe]);

    const handleDeleteClick = () => {
        const service = new RecipeService();
        service.deleteRecipe(props.recipe.id, token).then(response => {
            navigate("/");
        });
    };

    return (
        <>
            <Button color="danger"
                    onPress={onOpen}
                    variant="bordered"
                    className={"h-7"}
                    startContent={<Trash size={16}/>}>
                Delete recipe
            </Button>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Delete this recipe?</ModalHeader>
                            <ModalBody>
                                <p>
                                    Are you absolutely sure you want to delete this recipe? Once deleted it's gone forever
                                    and the recipe can not be recovered!
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onClose}>
                                    Abort
                                </Button>
                                <Button onPress={handleDeleteClick} color="danger" variant="light">
                                    Confirm deletion
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}

export default RecipeDeleteModal;
