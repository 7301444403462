import React, { FC } from 'react';
import { Button, Card, CardBody, CardFooter, Image, useDisclosure } from "@nextui-org/react";
import GroceryListDelete from "./GroceryListDelete";
import GroceryListDetails from "./GroceryListDetails";
import { GroceryList } from "../../models/GroceryList";

interface GroceryCardBlockProps {
    groceries: GroceryList
}

const GroceryCardBlock: FC<GroceryCardBlockProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-4">
            {[...Array(4)].map((_, index) => (
                <div key={index} className="flex flex-col w-full">
                    <Card shadow="sm" isPressable onPress={onOpen} className="max-sm:rounded-none w-full">
                        <CardFooter className="text-small justify-between">
                            <b>08-11-2024</b>
                            <p className="text-default-500">3 items</p>
                            <GroceryListDelete />
                        </CardFooter>
                    </Card>
                    <GroceryListDetails isOpen={isOpen} onClose={onClose} />
                </div>
            ))}
        </div>
    );
}

export default GroceryCardBlock;
