"use client";
import {createContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
export const ThemeContext
    = createContext({theme: localStorage.getItem("theme") ?? "dark", toggleTheme: () => {}});
export function Providers({children}: { children: React.ReactNode }) {

    const [theme, setTheme]
        = useState(localStorage.getItem("theme") ?? "dark");

    const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0();

    if(!isAuthenticated && !isLoading) {
        loginWithRedirect();
    }

    useEffect(() => {
        localStorage.setItem("theme", theme);
        document.documentElement.setAttribute(
            "class", theme
        );
    }, [theme]);

    return (
        <ThemeContext.Provider value={{theme,
            toggleTheme: () => setTheme(theme === "light" ? "dark" : "light"),
        }}>
            {children}
        </ThemeContext.Provider>
    )
}

