import React, { FC } from 'react';
import {Button, Card, CardFooter, CardHeader, Image} from "@nextui-org/react";
import {useNavigate} from 'react-router';

interface ModalContentProps {
    setModal: any
}

const ApproachModalContent: FC<ModalContentProps> = (props) => {
    const navigate = useNavigate();

    return(<>
        <Card isFooterBlurred className="w-1/3 h-[300px] col-span-12 sm:col-span-5">
            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                <p className="text-tiny text-white/60 uppercase font-bold">Create from scratch</p>
                <h4 className="text-black font-medium text-2xl">Customize</h4>
            </CardHeader>
            <Image
                removeWrapper
                alt="Card example background"
                className="z-0 w-full h-full scale-125 -translate-y-6 object-cover"
                src="https://nextui.org/images/card-example-6.jpeg"
            />
            <CardFooter className="absolute bg-white/30 bottom-0 border-t-1 border-zinc-100/50 z-10 justify-between">
                <Button onClick={() => navigate("/recipe/create")} className="text-tiny" color="primary" radius="full" size="sm">
                    Create from scratch
                </Button>
            </CardFooter>
        </Card>
        <Card isFooterBlurred className="w-full h-[300px] col-span-12 sm:col-span-7">
            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                <p className="text-tiny text-white/60 uppercase font-bold">Any recipe from the internet</p>
                <h4 className="text-white/90 font-medium text-xl">Use AI to copy the recipe into your cookbook</h4>
            </CardHeader>
            <Image
                removeWrapper
                alt="Relaxing app background"
                className="z-0 w-full h-full object-cover"
                src="https://nextui.org/images/card-example-5.jpeg"
            />
            <CardFooter
                className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                <div className="flex flex-grow gap-2 items-center">
                    <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="https://nextui.org/images/breathing-app-icon.jpeg"
                    />
                    <div className="flex flex-col">
                        <p className="text-tiny text-white/60">Favorite recipes from any site</p>
                        <p className="text-tiny text-white/60">Refined with AI</p>
                    </div>
                </div>
                <Button radius="full" size="sm" onClick={props.setModal}>Add using AI</Button>
            </CardFooter>
        </Card>
    </>)
}

export default ApproachModalContent;
