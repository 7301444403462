import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import Recipes from "./pages/Recipes";
import Recipe from "./pages/Recipe";
import FamilyShare from "./pages/FamilyShare";
import GroceryListOverview from "./components/GroceryList/GroceryListOverview";
import Layout from "./layout/Layout";
import CreateRecipe from "./pages/CreateRecipe";
import EditRecipe from "./pages/EditRecipe";

const AppRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        loader: async () => {
            return {};
        },
        children: [
            {
                path: "/",
                element: <Recipes />,
                loader: async () => {
                    return {};
                },
            },
            {
                path: "/recipe/:id",
                element: <Recipe />,
                loader: async () => {
                    return {};
                },
            },
            {
                path: "/recipe/:id/edit",
                element: <EditRecipe />,
                loader: async () => {
                    return {};
                },
            },
            {
                path: "/recipe/create",
                element: <CreateRecipe />,
                loader: async () => {
                    return {};
                },
            },
            {
                path: "/share",
                element: <FamilyShare />,
                loader: async () => {
                    return {};
                },
            },
            {
                path: "/grocery-list",
                element: <GroceryListOverview />,
                loader: async () => {
                    return {};
                },
            },
        ],
    },
]);

export default AppRouter;