import { useAuth0 } from '@auth0/auth0-react';

const useAuthToken = () => {
    const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const getToken = async () => {
        if (isAuthenticated) {
            return getAccessTokenSilently().then(result => {
                return result;
            });
        }
        return null;
    };
    return { getToken };
};

export default useAuthToken;
