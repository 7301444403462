import React, {FC, useEffect, useState} from 'react';
import Menu from "../components/Menu/Menu";
import {useNavigate, useParams} from 'react-router';
import RecipeTimeConsuming from "../components/RecipeTimeConsuming/RecipeTimeConsuming";
import RecipeText from "../components/RecipeText/RecipeText";
import RecipeIngredients from "../components/RecipeIngredients/RecipeIngredients";
import { Card, CardBody } from '@nextui-org/react';
import RecipeDetails from "../components/RecipeDetails/RecipeDetails";
import useAuthToken from "../hooks/useAuthToken";
import RecipeService from "../services/recipeService";
import {Recipe as RecipeModel} from "../models/Recipe";
import {useAuth0} from "@auth0/auth0-react";

interface RecipeProps {
}

const Recipe: FC<RecipeProps> = (props) => {
    let params = useParams();
    const [recipe, setRecipe] = useState({} as RecipeModel);
    const navigate = useNavigate();
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        getAccessTokenSilently().then(token => {
            if(token === null) {
                return;
            }
            const service = new RecipeService();
            const recipeId = parseInt(params.id ?? "");
            service.getRecipeById(recipeId, token).then(response => {
                if(response.status !== 200) {
                    navigate("/");
                }
                response.json().then(recipe => {
                    setRecipe(recipe);
                });
            });
        });
    }, []);

    return (
        <div className={"recipe-container"}>
            <RecipeDetails recipe={recipe} />
        </div>
    );
}

export default Recipe;
