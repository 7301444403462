import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import useAuthToken from "../hooks/useAuthToken";
import {Card, CardHeader, Divider} from "@nextui-org/react";
import {PlusCircle} from "@phosphor-icons/react";
import RecipeForm from "../components/RecipeForm/RecipeForm";
import RecipeService from "../services/recipeService";
import RecipeEditForm from "../components/RecipeEditForm/RecipeEditForm";
import {Recipe} from "../models/Recipe";
import {useAuth0} from "@auth0/auth0-react";

interface EditRecipeProps {
}

const EditRecipe: FC<EditRecipeProps> = (props) => {

    const [recipe, setRecipe] = useState<Recipe>();
    const [loading, setLoading] = useState<boolean>(true);

    const { id } = useParams();
    const {getAccessTokenSilently} = useAuth0();

    const fetchRecipe: any = async () => {
        setLoading(true);
        try {
            getAccessTokenSilently().then(result => {
                const service = new RecipeService();
                service.getRecipeById(parseInt(id ?? "0"), result).then(response => {
                    if (response.ok) {
                        response.json().then(json => {setRecipe(json)})
                    }
                });
            });
        } catch (error) {
            console.error("Failed to fetch recipe:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecipe();
    }, []);

    if(loading) {
        return (<></>);
    }
    return (
        <div>
            <div className={"container mx-auto"}>
                <div className={""}>
                    <Card className="my-4">
                        <CardHeader className="flex p-8">
                            <div>
                                <h1 className="text-4xl my-4 inline-flex gap-3 tracking-tight font-bold items-end">
                                    <PlusCircle weight="duotone" /> Edit Recipe
                                </h1>
                                <span className={"flex w-full"}>
                                    Perfect your recipe.
                                </span>
                            </div>
                        </CardHeader>
                        <Divider/>
                        <CardHeader className={"flex-col flex w-full items-start p-8"}>
                            <RecipeEditForm recipe={recipe}/>
                        </CardHeader>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default EditRecipe;
