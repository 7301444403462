import React from 'react'
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    useDisclosure
} from "@nextui-org/react";
import {Plus} from "@phosphor-icons/react";

const GroceryListAdd : React.FC = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [backdrop, setBackdrop] = React.useState('blur')

    const handleOpen = (backdrop: any) => {
        setBackdrop(backdrop)
        onOpen();
    }

    return (
        <div>
            <Button
                key={backdrop}
                variant="flat"
                color="success"
                onPress={() => handleOpen('blur')}
                className="capitalize mb-5 mt-5"
            >
                <Plus size={24} className="mr-2"/>
                Add Grocery Item
            </Button>

            <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Add a new grocery list</ModalHeader>
                            <ModalBody>
                                <p>
                                    Fill in the details of your grocery list.
                                </p>
                                <div key={"bordered"} className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                                    <Input type="text" variant={"bordered"} label="Name of list" placeholder="Enter name" />
                                </div>
                                <div>
                                    <Input type={"date"} label={"Date"} placeholder={"Enter date"} />
                                </div>
                                <hr />
                                <div className={"flex flex-row"}>
                                    <Input type="text" variant={"bordered"} label="Item" placeholder="Enter item" className={"me-4"}/>
                                    <Button className={"h-100"}><Plus /></Button>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" onPress={onClose}>
                                    Add list
                                </Button>
                                <Button className={"bg-content2"} variant="light" onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default GroceryListAdd;