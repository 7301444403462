import React, { FC } from 'react';
import Menu from "../Menu/Menu";
import RecipeTimeConsuming from "../RecipeTimeConsuming/RecipeTimeConsuming";
import { Card, CardBody } from "@nextui-org/react";
import RecipeIngredients from "../RecipeIngredients/RecipeIngredients";
import RecipeText from "../RecipeText/RecipeText";
import {Recipe} from "../../models/Recipe";
import recipe from "../../pages/Recipe";
import RecipeControlButtons from "../RecipeControlButtons/RecipeControlButtons";

interface RecipeDetailsProps {
    recipe: Recipe
}
const RecipeDetails: FC<RecipeDetailsProps> = (props) => {

    const baseUrl = process.env.REACT_APP_S3_URL ?? "";
    const defaultUrl = "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D";

    return (
        <div className="recipe-details-container">
            <div className="container mx-auto mt-5 px-4">
                <div className="flex justify-between pt-4 max-sm:flex-wrap">
                    <div className={"flex gap-4"}>
                        <RecipeTimeConsuming recipe={props.recipe} />
                    </div>
                    <div className={"flex gap-4 max-sm:mt-4"}>
                        <RecipeControlButtons recipe={props.recipe}/>
                    </div>
                </div>
                <div className="pt-4">
                    <h1 className="text-4xl leading-tight font-bold">
                        {props.recipe.title}
                    </h1>
                    <p className={"recipe-details-container_credits"}>
                        <span className="text-sm font-bold">source </span>
                        <span className="text-xs text-slate-500">{props.recipe.source}</span>
                    </p>
                </div>
                <div className="flex flex-wrap md:flex-nowrap gap-4 my-4">

                    <div className="flex-col">
                        <img
                            className="max-h-[550px] rounded-xl object-cover"
                            src={props.recipe.images?.length > 0
                                ? baseUrl + props.recipe.images[0].name
                                : defaultUrl}
                            alt="Lasagne Recipe"
                        />
                    </div>

                    <div className="flex-col w-1/2 max-sm:w-full">
                        {props.recipe.images?.length > 1 ? (<div className="columns-2 xl:flex gap-2 mb-4">
                            {props.recipe.images?.length > 1 ? <div className={"w-full"}>
                                <img
                                    src={props.recipe.images?.length > 1
                                        ? baseUrl + props.recipe.images[1].name
                                        : defaultUrl}
                                    alt="Lasagne Recipe"
                                    className="rounded-xl aspect-square"
                                />
                            </div> : ""}

                            {props.recipe.images?.length > 2 ? <div className={"w-full"}>
                                <img
                                    src={props.recipe.images?.length > 2
                                        ? baseUrl + props.recipe.images[2].name
                                        : defaultUrl}
                                    alt="Lasagne Recipe"
                                    className="rounded-xl aspect-square"
                                />
                            </div> : ""}
                        </div>) : ""}

                        <h2 className="text-3xl tracking-tight font-bold mb-4">Servings</h2>
                        <Card className="bg-slate-300 p-4 w-full">
                            <CardBody>
                                <div className="space-y-2">
                                    <div>
                                        <span className="text-xs text-slate-500">Servings</span>
                                        <p className={"dark:text-slate-800"}>{props.recipe.servings} Servings</p>
                                    </div>
                                    <div>
                                        <span className="text-xs text-slate-500">Active Time</span>
                                        <p className={"dark:text-slate-800"}>-</p>
                                    </div>
                                    <div>
                                        <span className="text-xs text-slate-500">Total Time</span>
                                        <p className={"dark:text-slate-800"}>1 hour 45 minutes</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>


                <div className="mt-5">
                    <RecipeIngredients recipe={props.recipe} />
                </div>

                <div className="flex flex-col">
                    <div className="my-4">
                        <RecipeText recipe={props.recipe} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecipeDetails;
