import {SettingsDto} from "../models/SettingsDto";
import {AccessDto} from "../models/AccessDto";
import {AllowedBooksDto} from "../models/AllowedBooksDto";

export default class ShareService {

    base: string = process.env.REACT_APP_API_URL ?? "";

    async GetMyInvites(token: string): Promise<AccessDto[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(this.base + "Share", requestOptions);
        return response.json();
    }

    async Invite(token: string, email: string)
    {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        let response = await fetch(this.base + "Share?email=" + email, requestOptions);
        return response.ok;
    }

    async GetAllowedBooks(token: string) : Promise<AllowedBooksDto[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(this.base + "Share/Books", requestOptions);
        return response.json();
    }
    async RemoveMember(token: string, email: string)
    {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(this.base + "Share/Member?email=" + email, requestOptions);
        return response.ok;
    }
    async GetMembers(token: string): Promise<AccessDto[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(this.base + "Share/Members", requestOptions);
        return response.json();
    }

    async Accept(token: string, email: string)
    {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(this.base + "Share?email=" + email, requestOptions);
    }

    async Deny(token: string, email: string)
    {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(this.base + "Share/Self?email=" + email, requestOptions);
    }
}