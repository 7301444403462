import React, { FC } from 'react';
import {Recipe} from "../../models/Recipe";
import {Button, useDisclosure} from "@nextui-org/react";
import {Pen, Trash} from "@phosphor-icons/react";
import {useNavigate} from "react-router";
import RecipeDeleteModal from "../RecipeDeleteModal/RecipeDeleteModal";


interface RecipeControlButtonsProps {
    recipe: Recipe;
}

const RecipeControlButtons: FC<RecipeControlButtonsProps> = (props) => {
    const navigate = useNavigate();

    return(<>
        <Button isIconOnly
                color="success"
                aria-label="Like"
                className={"h-7"}
                onClick={() => navigate("/recipe/" + props.recipe.id + "/edit")}>
            <Pen size={16}/>
        </Button>
        <RecipeDeleteModal recipe={props.recipe} />
    </>);
}

export default RecipeControlButtons;
