import React, { FC } from 'react';
import {Chip} from "@nextui-org/react";
import {CookingPot, Knife, Timer} from "@phosphor-icons/react";
import {Recipe as RecipeModel} from "../../models/Recipe";
import {Duration} from "luxon";
import IsoTimeFormatComponent from "../IsoTimeFormatComponent/IsoTimeFormatComponent";

interface RecipeTimeConsumingProps {
    recipe: RecipeModel
}

const RecipeTimeConsuming: FC<RecipeTimeConsumingProps> = (props) => {

    const totalTime = Duration.fromISO(props.recipe.times?.totalTime);
    const prepTime = Duration.fromISO(props.recipe.times?.prepTime);
    const cookTime = Duration.fromISO(props.recipe.times?.cookTime);

    return (
        <>
            {totalTime.isValid ? <Chip>
                <Timer className={"inline mt-[-3px] mr-1"} size={20} />
                Total: <IsoTimeFormatComponent time={totalTime} />
            </Chip> : ""}
            {prepTime.isValid ?<Chip>
                <Knife className={"inline mt-[-3px] mr-1"} size={20}/>
                Prep: <IsoTimeFormatComponent time={prepTime} />
            </Chip> : ""}
            {cookTime.isValid ? <Chip>
                <CookingPot className={"inline mt-[-3px] mr-1"} size={20}/>
                Cook: <IsoTimeFormatComponent time={cookTime} />
            </Chip> : ""}
        </>
    );
}

export default RecipeTimeConsuming;
