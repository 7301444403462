import React, {FC, useEffect, useRef, useState} from 'react';
import RecipeGridView from "../components/RecipeGridView/RecipeGridView";
import {Pagination} from "@nextui-org/react";
import Menu from "../components/Menu/Menu";
import DropdownFilter from "../components/FilterComponents/DropdownFilter/DropdownFilter";
import InputFilter from "../components/FilterComponents/InputFilter/InputFilter";
import {useAuth0} from "@auth0/auth0-react";
import ShareService from "../services/ShareService";
import RecipeService from "../services/recipeService";
import {RecipeDto} from "../models/RecipeDto";
import {Recipe} from "../models/Recipe";
import {AllowedBooksDto} from "../models/AllowedBooksDto";
import {FilterProvider, useFilter} from "../components/FilterContext/FilterContext";
interface RecipesProps {

}
const Recipes: FC<RecipesProps> = (props) => {

    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");
    const [allRecipes, setRecipes] = useState<Recipe[]>([]);
    const [ogRecipes, setOgRecipes] = useState<Recipe[]>([]);
    const [allowedBooks, setAllowedBooks] = useState<AllowedBooksDto[]>([]);
    const [filterToggleState, setFilterToggleState] = useState<boolean | null>(null);

    const getRecipes = () => {
        const service = new RecipeService();
        service.getRecipes(token.current).then(recipeArray => {
            setRecipes(recipeArray);
            setOgRecipes(recipeArray);
        });
    }

    useEffect(() => {
        if(filterToggleState == null && window.innerWidth >= 640) {
            setFilterToggleState(true);
        }
        getAccessTokenSilently().then(result => {
            token.current = result;
            getRecipes();
            getSharedRecipes();
        });
    }, [getAccessTokenSilently]);

    const getCourseOptions = () => {
        const courses = new Set(
            ogRecipes.map(recipe => recipe.course.toLowerCase())
        );
        return Array.from(courses);
    };

    const getTimeOptions = () => {
        return Object.freeze([
            "< 20 minutes",
            "< 30 minutes",
            "< 1 hour",
            "< 2 hours"
        ]);
    };

    const getCategoryOptions = () => {
        const categories = new Set(
            ogRecipes.map(recipe => recipe.category.toLowerCase())
        );
        return Array.from(categories);
    };

    const getSharedRecipes = async () => {
        try {
            const share = new ShareService();
            const allowedBooks = await share.GetAllowedBooks(token.current);
            setAllowedBooks(allowedBooks);
            const newRecipes = allowedBooks
                .flatMap(book => book.recipes)
                .filter(recipe =>
                    !allRecipes.some(existingRecipe => existingRecipe.title === recipe.title)
                );
            setRecipes(prev => [...prev, ...newRecipes]);
        } catch (error) {
            console.error("Error fetching shared recipes:", error);
        }
    };

    const toggleFiltersCode = () => {
        if(filterToggleState) {
            setFilterToggleState(false);
        } else {
            setFilterToggleState(true);
        }
    }

    return (
        <div>
            <FilterProvider>
                <div className={"container mx-auto"}>
                    <div className={"flex gap-2 pt-4"}>
                        <DropdownFilter
                            defaultButtonValue={"Category"}
                            ariaLabel={"Category selection"}
                            recipeFilterString={"category"}
                            options={getCategoryOptions()}
                        />
                        <DropdownFilter
                            defaultButtonValue={"Course"}
                            ariaLabel={"Course selection"}
                            recipeFilterString={"course"}
                            options={getCourseOptions()}
                        />
                        {/*<DropdownFilter defaultButtonValue={"Course"} />*/}
                        <InputFilter />
                    </div>
                    <RecipeGridView recipes={allRecipes} className={"py-4"} />
                    <Pagination showControls total={10} initialPage={1} />
                </div>
            </FilterProvider>
        </div>
    );
}
export default Recipes;
