import { createSlice } from '@reduxjs/toolkit'

export const chooseSlice = createSlice({
    name: 'choose',
    initialState: {
        value: "choice"
    },
    reducers: {
        setChooseState: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const { setChooseState } = chooseSlice.actions
export default chooseSlice.reducer