import React, { FC } from 'react';
import {
    Navbar, NavbarBrand, NavbarContent, NavbarItem,
    NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button, Avatar, Badge
} from "@nextui-org/react";
import UserAvatar from "../UserAvatar/UserAvatar";
import {
    Bird,
    BookOpen,
    CalendarPlus,
    ClipboardText, List,
    MagnifyingGlass,
    PlusCircle,
    Power,
    UserCircle, UsersThree
} from "@phosphor-icons/react";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import {NavLink} from "react-router-dom";

interface NavbarProps {}

const Menu: FC<NavbarProps> = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <Navbar onMenuOpenChange={setIsMenuOpen} isBordered position={"static"}>
            <NavbarContent justify={"start"}>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
                <NavbarBrand>
                    <NavLink to={"/"}>
                        COOK
                        <Bird size={25} className={"inline mt-[-5px] ml-[0px] mr-[-1px]"} />
                        BIRD
                    </NavLink>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarItem isActive>
                    <Link color="foreground" href="/" aria-current="page">
                        <BookOpen size={18} weight="bold"/>
                        <span className={"ml-1"}>My Recipes</span>
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" href="/share">
                        <CalendarPlus size={18} />
                        <span className={"ml-1"}>FamilyShare</span>
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" href="/grocery-list">
                        <List size={18} />
                        <span className={"ml-1"}>Grocery list</span>
                    </Link>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem>
                    <UserAvatar />
                </NavbarItem>
            </NavbarContent>
            <NavbarMenu>
                <NavbarMenuItem>
                    <Link className="w-full" href="/" size="lg">
                        Home
                    </Link>
                    <Link className={"w-full"} color="foreground" href="/share">
                        FamilyShare
                    </Link>
                    <Link color="foreground" className={"w-full"} href="/grocery-list">
                        Grocery
                    </Link>
                </NavbarMenuItem>
            </NavbarMenu>
        </Navbar>
    );
};

export default Menu;
