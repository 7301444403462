import React from 'react';
import {
    Button,
    Input, Listbox, ListboxItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    useDisclosure
} from "@nextui-org/react";
import {Plus} from "@phosphor-icons/react";
import {ListboxWrapper} from "./ListBoxWrapper";


interface GroceryListDetailsProps {
    isOpen: boolean;
    onClose: () => void;
}

const GroceryListDetails: React.FC<GroceryListDetailsProps> = ({ isOpen, onClose }) => {
    const [selectedKeys, setSelectedKeys] = React.useState<Set<string>>(new Set(["text"]));

    const selectedValue = React.useMemo(
        () => Array.from(selectedKeys).join(", "),
        [selectedKeys]
    );
    return (
        <div>
            <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Grocery list | 08-11-2024</ModalHeader>
                            <ModalBody>
                                <ListboxWrapper>
                                    <Listbox
                                        aria-label="Multiple selection example"
                                        variant="flat"
                                        disallowEmptySelection
                                        selectionMode="multiple"
                                        selectedKeys={selectedKeys}
                                        onSelectionChange={(keys) => setSelectedKeys(keys as Set<string>)}
                                    >
                                        {["300gr chicken", "3 chocolate bars", "1 peachy", "1 blue"].map((key) => (
                                            <ListboxItem
                                                key={key}
                                                className={selectedKeys.has(key) ? "line-through" : ""}
                                            >
                                                {key.charAt(0).toUpperCase() + key.slice(1)}
                                            </ListboxItem>
                                        ))}
                                    </Listbox>
                                </ListboxWrapper>
                            </ModalBody>
                            <ModalFooter>
                                <Button className={"bg-content2"} variant="light" onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default GroceryListDetails;