import React, {FC, useEffect, useState} from 'react';
import {Input} from "@nextui-org/react";


interface ControllerInputProps {
    name: string
    placeholder: string
    value: any
    type: string
    className?: string
    label: string
}

const ControlledInput: FC<ControllerInputProps> = (props) => {

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const [inputValue, setInputValue] = useState(props.value);

    const onChangeHandler = (event:any) => {
        setInputValue(event.target.value);
    };

    return(<>
        <Input type={props.type}
               id={props.name}
               name={props.name}
               value={inputValue}
               variant="bordered"
               label={props.label}
               onChange={onChangeHandler}
               className={props.className}
               placeholder={props.placeholder}/>
    </>);
}

export default ControlledInput;
