import React, { FC, useState, useEffect, useMemo } from 'react';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { useFilter } from "../../FilterContext/FilterContext";

interface CategoryFilterDropdownProps {
    defaultButtonValue: string;
    options: any[];
    className?: string;
    ariaLabel: string;
    recipeFilterString: string;
}

const DropdownFilter: FC<CategoryFilterDropdownProps> = ({
                                                             defaultButtonValue,
                                                             options,
                                                             className,
                                                             ariaLabel,
                                                             recipeFilterString
                                                         }) => {
    const { filters, setFilters } = useFilter();
    const [selectedKeys, setSelectedKeys]
        = useState<Set<any>>(new Set());

    const selectedValue = useMemo(() => {
        return Array.from(selectedKeys).join(", ").replaceAll("_", " ");
    }, [selectedKeys, defaultButtonValue]);

    useEffect(() => {
        setFilters((prevFilters) => {
            const filterValues = Array.from(selectedKeys);
            if (filterValues.length === 0) {
                const { [recipeFilterString]: _, ...restFilters } = prevFilters;
                return restFilters;
            }
            return { ...prevFilters, [recipeFilterString]: filterValues };
        });
    }, [selectedKeys, recipeFilterString, setFilters]);

    return (
        <div className={className}>
            <Dropdown>
                <DropdownTrigger>
                    <Button variant="bordered" className="capitalize">
                        {selectedValue || defaultButtonValue}
                    </Button>
                </DropdownTrigger>
                <DropdownMenu
                    aria-label={ariaLabel}
                    variant="flat"
                    closeOnSelect={false}
                    disallowEmptySelection={false}
                    selectionMode="multiple"
                    selectedKeys={selectedKeys}
                    onSelectionChange={(keys) => setSelectedKeys(keys as Set<React.Key>)}
                >
                    {options.map((option) => (
                        <DropdownItem key={option}>{option}</DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default DropdownFilter;
