import React, {useEffect} from 'react';
import Menu from "../components/Menu/Menu";
import {Outlet} from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

const Layout : React.FC = () => {
    const {isAuthenticated} = useAuth0();

    if(isAuthenticated) {
        return (
            <div>
                <header>
                    <Menu />
                </header>
                <main>
                    <Outlet />
                </main>
            </div>
        )
    } else {
        return (<></>);
    }

}

export default Layout;