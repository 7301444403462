import { Input } from '@nextui-org/react';
import React, { FC } from 'react';
import {useFilter} from "../../FilterContext/FilterContext";
interface SearchFilterInputProps {}

const InputFilter: FC<SearchFilterInputProps> = () => {
      const { filters, setFilters } = useFilter();
      const handleFilterChange = (filterName: string, value: any) => {
            setFilters({ ...filters, [filterName]: value });
      };

      const handleTitleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const titleValue = e.target.value.toLowerCase();
            handleFilterChange("title", titleValue);
      };

      const handleClearFilter = () => {
            handleFilterChange("title", "");
      }

      return (
            <div>
                  <Input
                      isClearable
                      type="email"
                      variant="bordered"
                      placeholder="Search your recipes"
                      defaultValue=""
                      onClear={handleClearFilter}
                      onChange={handleTitleFilterChange}
                      className="max-w-xs"
                  />
            </div>
      );
}

export default InputFilter;
