import React, {FC, useEffect, useRef, useState} from 'react';
import {Avatar, Button, Listbox, ListboxItem} from "@nextui-org/react";
import {AccessDto} from "../../models/AccessDto";
import {useAuth0} from "@auth0/auth0-react";
import ShareService from "../../services/ShareService";
import {SpinnerGap} from "@phosphor-icons/react";

const UserInvitationListbox: FC = () => {
    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");
    const [invites, setInvites] = useState<AccessDto[]>([]);
    const [isLoading, setLoading] = useState(true);

    const accept = async (access: AccessDto) => {
        const updatedInvites = invites.map((invite) =>
            invite.userId === access.userId ? {...invite, accepting: true} : invite
        );
        setInvites(updatedInvites);
        try {
            const share = new ShareService();
            await share.Accept(token.current, access.userId);
            getInvites();
        } catch (error) {
            console.error("Failed to accept:", error);
        } finally {
            const updatedInvites = invites.map((invite) =>
                invite.userId === access.userId ? {...invite, accepting: false} : invite
            );
            setInvites(updatedInvites);
        }
    };

    const deny = async (access: AccessDto) => {
        const updatedInvites = invites.map((invite) =>
            invite.userId === access.userId ? {...invite, denying: true} : invite
        );
        setInvites(updatedInvites);

        try {
            const share = new ShareService();
            await share.Deny(token.current, access.userId);
            getInvites();
        } catch (error) {
            console.error("Failed to deny:", error);
        } finally {
            const updatedInvites = invites.map((invite) =>
                invite.userId === access.userId ? {...invite, denying: false} : invite
            );
            setInvites(updatedInvites);
        }
    };

    const getInvites = async () => {
        setLoading(true);
        try {
            const share = new ShareService();
            const res = await share.GetMyInvites(token.current);
            const unacceptedInvites = res.filter((access) => !access.accepted).map((invite) => ({
                ...invite,
                accepting: false,
                denying: false,
            }));
            setInvites(unacceptedInvites);
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch invites:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAccessTokenSilently().then((result) => {
            token.current = result;
            getInvites();
        });
    }, [getAccessTokenSilently]);

    const items = invites.map((item: AccessDto) => {
        return (<ListboxItem key={item.userId} textValue={item.userId}>
            <div className="flex gap-2 items-center">
                <Avatar alt={item.userId} className="flex-shrink-0" size="sm" src={""}/>
                <div className="flex gap-2">
                                <span className="flex-inline text-small">
                                    invited by<br/><b>{item.userId}</b>
                                </span>
                    <Button
                        type="button"
                        isLoading={item.accepting}
                        isIconOnly
                        onClick={() => accept(item)}
                        color={"success"}
                        radius={"full"}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 18"
                        >
                            <path
                                d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z"/>
                        </svg>
                    </Button>
                    <Button
                        type="button"
                        isIconOnly
                        isLoading={item.denying}
                        radius={"full"}
                        color={"danger"}
                        onClick={() => deny(item)}
                    >
                        <svg
                            className="w-3 h-3 rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 18"
                        >
                            <path
                                d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z"/>
                        </svg>
                    </Button>
                </div>
            </div>
        </ListboxItem>);
    })

    return (
        <div className="w-full rounded-small border-default-200 dark:border-default-100">
            <Listbox
                classNames={{
                    base: "text-left",
                    list: "max-h-[400px] overflow-scroll",
                }}
                label="Recipebook invites"
                variant="flat"
                emptyContent={(isLoading ?
                    <div className={"justify-start flex w-full flex-row items-center"}>
                        <SpinnerGap className={"animate-spin"} />
                        <span className={"text-sm ml-2"}>Loading</span>
                    </div> : <>No Items</>)
                }
            >
                {items}
            </Listbox>
        </div>
    )
}
export default UserInvitationListbox;
