import React, { FC, useState } from 'react';
import { Button, Input } from "@nextui-org/react";
import ReactQuill from "react-quill";
import FormIngredients from "../FormIngredients/FormIngredients";
import FormImages from "../FormImages/FormImages";
import { RecipeDto } from "../../models/RecipeDto";
import { IngredientDto } from "../../models/IngredientDto";
import useAuthToken from "../../hooks/useAuthToken";
import RecipeService from "../../services/recipeService";
import { Recipe } from "../../models/Recipe";
import { useNavigate } from "react-router";

interface RecipeFormProps {}

const RecipeForm: FC<RecipeFormProps> = () => {

    const [recipeText, setRecipeText] = useState('');
    const [ingredients, setIngredients] = useState([] as IngredientDto[]);
    const [fileFormData, setFileFormData] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const token = useAuthToken();
    const navigate = useNavigate();

    const packFiles = (files: File[]) => {
        const data = new FormData();
        files.forEach((file, i) => {
            data.append(`Images`, file, file.name);
        });
        return data;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage(null); // reset error message on each submit attempt

        const formElements = event.currentTarget.elements as any;
        let newRecipe: RecipeDto = {
            id: 0,
            userId: "0",
            source: "user-created",
            title: formElements.title?.value,
            category: formElements.category?.value,
            course: formElements.course?.value,
            recipe: recipeText,
            servings: formElements.servings?.value,
            nutrition: {
                id: 0,
                fat: formElements.fat?.value,
                protein: formElements.protein?.value,
                carbs: formElements.carbs?.value,
                calories: formElements.calories?.value,
            },
            times: {
                id: 0,
                prepTime: formElements.prepTime?.value ?? "",
                cookTime: formElements.cookTime?.value ?? "",
                totalTime: formElements.totalTime?.value ?? "",
            },
            images: [],
            ingredients: ingredients.map(ingredient => ({ ...ingredient, id: undefined })),
        };

        const formData = new FormData();
        formData.append('Recipe', JSON.stringify(newRecipe));

        // Pack files to avoid duplicate code
        const fileData = packFiles(fileFormData);
        fileData.forEach((value, key) => formData.append(key, value));

        const authToken = await token.getToken();
        if (!authToken) return;

        const service = new RecipeService();

        try {
            const response = await service.postRecipe(formData, authToken);
            window.scrollTo({ top: 0, behavior: "smooth" });

            if (response.status === 200) {
                const localRecipe = await response.json() as Recipe;
                navigate(`/recipe/${localRecipe.id}`);
            } else if (response.status === 429) {
                setErrorMessage("You have reached the maximum number of recipes allowed. Support Cookbird upgrade your " +
                    "account to get unlimited recipes!");
            } else if (response.status === 400) {
                setErrorMessage("There was an issue with your submission. Please check your inputs.");
            } else if (response.status === 500) {
                setErrorMessage("Server error. Please try again later.");
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
        } catch (error) {
            setErrorMessage("Failed to submit. Please check your internet connection.");
        }
    };


    return (
        <form onSubmit={handleSubmit} className={"w-3/5 max-lg:w-full"}>

            {errorMessage && (
                <span className="flex mb-4 text-danger">
                    {errorMessage}
                </span>
            )}

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Describe the recipe in a short sentence</b></span>
            </div>

            <div className="flex w-full mt-2">
                <Input
                    type="text"
                    variant="bordered"
                    name="title"
                    label="Recipe title"
                    placeholder="Create a title for your new recipe"
                />
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Categorize your recipe</b></span>
            </div>

            <div className="flex max-sm:flex-col w-full gap-4 mt-2">
                <Input type="text" name="category" variant="bordered" label="Category" placeholder="Recipe category"/>
                <Input type="text" name="course" variant="bordered" label="Course" placeholder="e.g., Main Course"/>
                <Input type="text" name="servings" variant="bordered" label="Servings" placeholder="Amount of servings"/>
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Timings</b></span>
                <span>Create timings for your recipe (optional)</span>
            </div>

            <div className="flex max-sm:flex-col w-full gap-4 mt-2">
                <Input type="text" name="cookTime" variant="bordered" label="Cook time"
                       placeholder="1 hour 30 minutes"/>
                <Input type="text" name="prepTime" variant="bordered" label="Prep time" placeholder="30 minutes"/>
                <Input type="text" name="totalTime" variant="bordered" label="Total Time" placeholder="2 hours"/>
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Nutrition</b></span>
                <p>Fill out nutritional information (optional)</p>
            </div>

            <div className="flex max-sm:flex-col w-full gap-4 mt-2">
                <Input type="text" name="carbs" variant="bordered" label="Carbs" placeholder="Carbohydrates"/>
                <Input type="text" name="fat" variant="bordered" label="Fat" placeholder="Fat content"/>
                <Input type="text" name="protein" variant="bordered" label="Protein" placeholder="Protein content"/>
                <Input type="text" name="calories" variant="bordered" label="Calories" placeholder="Calories"/>
            </div>

            <div className="flex flex-col ml-[1px] w-full mt-4">
                <span><b>Directions</b></span>
                <p>Describe your recipe in detail.</p>
            </div>

            <div className="flex w-full gap-4 mt-4">
                <ReactQuill
                    value={recipeText}
                    onChange={setRecipeText}
                    theme="snow"
                    className="border-none dark:bg-content2 dark:text-default-500 ring-2 ring-gray-100 dark:ring-content3 w-full text-black min-h-[300px] rounded-xl"
                    modules={{toolbar: true}}
                />
            </div>

            <FormIngredients set={setIngredients}/>
            <FormImages setFileData={setFileFormData}/>

            <Button
                color="primary"
                className="mt-4"
                variant="bordered"
                type="submit"
            >
                Create new recipe
            </Button>
        </form>
    );
};

export default RecipeForm;
