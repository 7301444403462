import { createSlice } from '@reduxjs/toolkit'

export const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState: {
        value: ""
    },
    reducers: {
        visible: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const { visible } = dropdownSlice.actions
export default dropdownSlice.reducer