import React, {FC, useState} from 'react';
import RecipeCardBlock from "../RecipeCardBlock/RecipeCardBlock";
import RecipeCardCreateBlock from "../RecipeCardCreateBlock/RecipeCardCreateBlock";
import ApproachModal from "../ApproachModal/ApproachModal";
import {Recipe} from "../../models/Recipe";
import {Duration} from "luxon";
import {useFilter} from "../FilterContext/FilterContext";

interface RecipeGridViewProps {
    className: string,
    recipes: Recipe[]
}

const RecipeGridView: FC<RecipeGridViewProps> = (props) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const { filters } = useFilter();
    const filteredRecipes = props.recipes.filter(recipe => {
        for (const filterKey in filters) {
            if (Object.prototype.hasOwnProperty.call(filters, filterKey)) {
                console.log(filters);
                const filterValues = filters[filterKey];
                if (filterKey === 'title') {
                    if (filterValues && filterValues.length > 0 && !recipe
                        .title
                        .toLowerCase()
                        .includes(filterValues)) {
                        return false;
                    }
                }
                if(filterKey === "books") {
                    if (filterValues && filterValues.length > 0 && recipe
                        .userId
                        .includes(filterValues)) {
                        return false;
                    }
                }
                if (filterKey === "time") {
                    if (filterValues.length > 0) {
                        // Check if the recipe's totalTime falls within any of the selected time filter values
                        const matchesTimeFilter = filterValues.some((filterIsoTime: string) => {
                            const recipeTime = Duration.fromISO(recipe.times?.totalTime);
                            const filterValue = Duration.fromISO(filterIsoTime);
                            return recipeTime <= filterValue;
                        });
                        if (!matchesTimeFilter) {
                            return false;
                        }
                    }
                }

                if(filterKey === "course" || filterKey === "category") {
                    if (filterValues.length > 0
                            && !filterValues.includes((recipe as any)[filterKey].toLowerCase())) {
                        return false;
                    }
                }
            }
        }
        return true;
    });

    return (
        <>
            <ApproachModal isOpen={isModalOpen} onClose={handleClose} />
            <div className={"flex-wrap auto-rows-fr grid lg:grid-cols-4 md:grid-cols-3 gap-4 " + props.className}>
                <RecipeCardCreateBlock onOpen={handleOpen} />
                {filteredRecipes.map((recipe: Recipe) => {
                    return (<RecipeCardBlock key={recipe.id} recipe={recipe}/>);
                })}
            </div>
        </>
    );
};
export default RecipeGridView;
