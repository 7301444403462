import React, { FC } from 'react';
import {Card, CardBody, CardFooter, Image} from "@nextui-org/react";
import {Plus} from "@phosphor-icons/react";
import ApproachModal from "../ApproachModal/ApproachModal";

interface RecipeCardCreateBlockProps {
    onOpen: () => void;
}

const RecipeCardCreateBlock: FC<RecipeCardCreateBlockProps> = (props) => {

    const handleClick = () => {
        props.onOpen();
    };

    return (
        <>
            <Card className={"max-sm:rounded-none border-slate-500 border-1"} shadow="sm"
                  isPressable
                  onPress={handleClick}>
                <CardBody className="overflow-visible p-0 justify-center">
                    <Plus size={48} className={"self-center text-gray-700"}/>
                </CardBody>
                <CardFooter className="text-small text-left justify-start place-items-start">
                    Add Recipe
                </CardFooter>
            </Card>
        </>
    );
};

export default RecipeCardCreateBlock;
