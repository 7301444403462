import React, { FC } from 'react';
import {Card, CardBody, CardHeader} from "@nextui-org/react";
import {Dna, Drop, Fire, Grains} from "@phosphor-icons/react";
import {Recipe} from "../../models/Recipe";


interface RecipeTextProps {
    recipe: Recipe
}

const RecipeText: FC<RecipeTextProps> = (props) => {
    const createMarkup = () => {
        return {__html: props.recipe.recipe};
    }
    let recipeHtml = createMarkup();

    return(<>
        <h2 className={"text-3xl tracking-tight font-bold mb-4"}>Directions</h2>
        <Card className={"border-1"}>
            <CardHeader className={"py-4 bg-slate-200"}>
                <div className={"inline-flex cursor-default"}>
                    <Grains className={"w-4 h-4 mt-[3px] text-gray-500"}/>
                    <span title="Carbs" className={"ml-[3px] dark:text-gray-500"}>23</span>
                </div>
                <div className={"inline-flex ml-2 cursor-default"}>
                    <Drop className={"w-4 h-4 mt-[3px] text-gray-500"}/>
                    <span title="Fat" className={"ml-[3px] dark:text-gray-500"}>24</span>
                </div>
                <div className={"inline-flex ml-2 cursor-default"}>
                    <Dna className={"w-4 h-4 mt-[3px] text-gray-500"}/>
                    <span title="Protein" className={"ml-[3px] dark:text-gray-500"}>88</span>
                </div>
                <div className={"inline-flex ml-2 cursor-default"}>
                    <Fire className={"w-4 h-4 mt-[3px] text-gray-500"}/>
                    <span title="Calories" className={"ml-[3px] dark:text-gray-500"}>125</span>
                </div>
            </CardHeader>
            <CardBody>
                <div className={"recipeDesc text-base"} dangerouslySetInnerHTML={recipeHtml}></div>
            </CardBody>
        </Card>
    </>);
}
export default RecipeText;
