import React, {FC, useEffect, useState} from 'react';
import {Trash} from "@phosphor-icons/react";


interface FormImagesProps {
    setFileData: any
}

const FormImages: FC<FormImagesProps> = (props) => {

    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        props.setFileData(files);
    }, [files, props]);

    const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        }
    }

    const deleteFile = (fileToRemove: File) => {
        const newFiles = files.filter(file => file !== fileToRemove);
        setFiles(newFiles);
    }

    return(<>
        <div className="flex flex-col ml-[1px] w-full mt-4">
            <span><b>Images</b></span>
            <p>Upload 1 or more images to better identify your recipe. The first image is used as the thumbnail.</p>
        </div>

        <div className="mb-4 flex items-center justify-start cursor-pointer flex-column w-full gap-4">
            {files.map((file, key) => (
                <div className={"flex gap-4"} onClick={() => deleteFile(file)}>
                    <span className={"absolute m-[5px] text-white"}>
                        <Trash size={23}/>
                    </span>
                    <img
                        key={key}
                        className={"max-h-[250px] object-fit-cover object-center rounded border-white border-2"}
                        src={URL.createObjectURL(file)}
                    />
                </div>
            ))}
        </div>

        <div className="mb-4 mt-4 flex items-center justify-center w-full">
            <label htmlFor="image" className="flex flex-col items-center justify-center
                        w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer
                        bg-gray-50 dark:bg-content2 dark:border-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg className="w-8 h-8 mb-4 text-gray-500"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 20 16">
                        <path stroke="currentColor" strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5
                                           0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0
                                           0L8 8m2-2 2 2"/>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">
                                        Click to upload
                                    </span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                    </p>
                </div>
                <input id="image"
                       type="file"
                       name="image"
                       className="hidden"
                       multiple
                       onChange={onFilesChange}
                />
            </label>
        </div>
    </>);
}

export default FormImages;
