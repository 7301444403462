import React from 'react';
import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/react";
import {Plus, Trash} from "@phosphor-icons/react";

const GroceryListDelete : React.FC = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [backdrop, setBackdrop] = React.useState('blur')

    const handleOpen = (backdrop: any) => {
        setBackdrop(backdrop)
        onOpen();
    }

    return (
        <div>
            <Button
                key={backdrop}
                variant="flat"
                color="danger"
                onPress={() => handleOpen('blur')}
                className="capitalize"
            >
                <Trash size={24} />
            </Button>

            <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Do you want to delete your grocery list?</ModalHeader>
                            <ModalBody>
                                <p>
                                    This action cannot be undone. Are you sure you want to delete your grocery list?
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onPress={onClose}>
                                    Delete
                                </Button>
                                <Button className={"bg-content2"} onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default GroceryListDelete;