import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {NextUIProvider} from "@nextui-org/react";
import {
    RouterProvider,
} from "react-router-dom";
import {Providers} from "./providers/Provider";
import {Auth0Provider} from '@auth0/auth0-react';
import store from "./store";
import {Provider} from "react-redux";
import AppRouter from "./AppRouter";
import CreateRecipe from "./pages/CreateRecipe";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Auth0Provider
          domain="dev-bdj1m3kl6sw7slly.us.auth0.com"
          clientId="W5tMoWZF3WC47HZ42BLNPyYbKxrMwEiE"
          authorizationParams={{
              redirect_uri: window.location.origin,
              audience: "https://dev-bdj1m3kl6sw7slly.us.auth0.com/api/v2/"
          }}
          cacheLocation= 'localstorage'>
          <Providers>
              <Provider store={store}>
                  <NextUIProvider>
                          <RouterProvider router={AppRouter}/>
                  </NextUIProvider>
              </Provider>
          </Providers>
      </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
