import React from 'react';
import {List} from "@phosphor-icons/react";
import GroceryCardBlock from "./GroceryCardBlock";
import GroceryListAdd from "./GroceryListAdd";
import {GroceryList} from "../../models/GroceryList";

const GroceryListOverview : React.FC = () => {
    return (
        <div className={"container mx-auto"}>
            <h1 className="text-4xl my-4 inline-flex gap-3 tracking-tight font-bold">
                <List weight="duotone"/> Grocery list
            </h1>

            <GroceryListAdd />
            <GroceryCardBlock groceries={{} as GroceryList} />
        </div>
    )
}

export default GroceryListOverview;