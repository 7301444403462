import React, { FC } from 'react';
import {Duration} from "luxon";

interface IsoTimeFormatComponentProps {
    time: Duration
}

const IsoTimeFormatComponent: FC<IsoTimeFormatComponentProps> = (props) => {

    const hrs = props.time.hours;
    const min = props.time.minutes;

    return (<>
        { hrs > 0 ? `${hrs}hr` : "" }
        { min > 0 ? `${min}min` : "" }
    </>);
};

export default IsoTimeFormatComponent;
