import React, { FC } from 'react';
import {Button, Card, CardBody, CardHeader, Link} from "@nextui-org/react";
import UserInvitationListbox from "../UserInvitationListbox/UserInvitationListbox";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import {Power} from "@phosphor-icons/react";
import {useAuth0} from "@auth0/auth0-react";


interface SettingsPopoverProps {}

const SettingsPopover: FC<SettingsPopoverProps> = () => {
    const { logout } = useAuth0();
    return (<Card className="w-[400px]">
        <CardHeader className="flex-row flex flex-wrap w-full">
            <Link className={"flex text-sm cursor-pointer"}
                  onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>
                <Power/>
                <span className={"ml-2"}>Logout</span>
            </Link>
            <ThemeSwitcher/>
        </CardHeader>
        <CardBody className="text-small text-default-400">
            <UserInvitationListbox/>
        </CardBody>
    </Card>)
}

export default SettingsPopover;
