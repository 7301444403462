import React, { FC } from 'react';
import {Listbox, ListboxItem} from "@nextui-org/react";
import {Recipe} from "../../models/Recipe";

interface RecipeIngredientsProps {
    recipe: Recipe
}

const RecipeIngredients: FC<RecipeIngredientsProps> = (props) => {

    const [selectedKeys, setSelectedKeys] = React.useState(new Set([""]));

    return (
        <>
            <h2 className={"text-3xl tracking-tight font-bold mb-4"}>Ingredients</h2>
            <div className="border-small px-1 py-2 rounded-small
                            border-default-200 dark:border-default-100 max-sm:w-full">
                <Listbox
                    aria-label="Multiple selection example"
                    variant="flat"
                    disallowEmptySelection
                    selectionMode="multiple"
                    selectedKeys={selectedKeys}
                    onSelectionChange={(keys) => setSelectedKeys(keys as Set<string>)}
                >
                    {props.recipe?.ingredients?.map(ingredient => {

                        return <ListboxItem
                            key={ingredient.id}
                            textValue={ingredient.name}
                            className={"text-base"}
                        >
                            {selectedKeys.has(ingredient.id.toString())
                                ? <span className={"line-through"}>{ingredient.name}</span>
                                : <span>{ingredient.name}</span>
                            }
                        </ListboxItem>
                    })}
                </Listbox>
            </div>
        </>
    );
}
export default RecipeIngredients;
