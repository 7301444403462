import {Card, CardBody, CardFooter, Chip, Image} from '@nextui-org/react';
import React, { FC } from 'react';
import {Recipe} from "../../models/Recipe";
import {useNavigate} from "react-router";

interface RecipeCardBlockProps {
    recipe: Recipe
}

const RecipeCardBlock: FC<RecipeCardBlockProps> = (props) => {
    const defaultUrl = "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D";
    const baseUrl: string = process.env.REACT_APP_S3_URL ?? "";
    const navigate = useNavigate();

    return(<Card className={"max-sm:rounded-none"} shadow="sm" isPressable
                 onPress={() => navigate("/recipe/" + props.recipe.id)}>
        <CardBody className="overflow-visible p-0">
            <Image
                shadow="sm"
                radius="none"
                width="100%"
                alt={props.recipe.title}
                className="w-full object-cover h-[250px]"
                src={(baseUrl + props.recipe.images[0]?.name) ?? defaultUrl}
            />
        </CardBody>
        <CardFooter className="text-small text-left justify-start place-items-start	">
            {props.recipe.title}
        </CardFooter>
    </Card>);
}

export default RecipeCardBlock;
