import React, {FC, useEffect, useState} from 'react';
import RecipeGridView from "../components/RecipeGridView/RecipeGridView";
import {
    Avatar, Button, Card, CardBody, CardFooter,
    CardHeader, Chip, Divider, Input, Listbox, ListboxItem, Image, Pagination, ScrollShadow, Spinner
} from "@nextui-org/react";
import Menu from "../components/Menu/Menu";
import DropdownFilter from "../components/FilterComponents/DropdownFilter/DropdownFilter";
import InputFilter from "../components/FilterComponents/InputFilter/InputFilter";
import {SpinnerGap, UsersThree} from "@phosphor-icons/react";
import type { Selection } from "@nextui-org/react";
import {useAuth0} from "@auth0/auth0-react";
import ShareService from "../services/ShareService";
import {AccessDto} from "../models/AccessDto";

interface RecipesProps {}
const Recipes: FC<RecipesProps> = (props) => {

    const [token, setToken] = useState("");
    const [members, setMembers] = useState<AccessDto[]>([]);
    const [isLoading, setLoading] = useState(false);

    const {getAccessTokenSilently} = useAuth0();

    const [emptyEmailError, setEmptyEmailError] = useState(false);

    const updateMembers = (localToken: string) =>
    {
        setLoading(true);
        const share = new ShareService();
        share.GetMembers(localToken).then(r => {
            setMembers(r);
            setLoading(false);
        });
    }

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            setToken(result);
            updateMembers(result);
        });
    }, [getAccessTokenSilently]);

    const submitInvite = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEmptyEmailError(false);
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string ?? "";
        if (email.trim() !== "") {
            const share = new ShareService();
            share.Invite(token, email).then(response => {
                updateMembers(token);
            });
        } else {
            setEmptyEmailError(true);
        }
    }

    return (
        <div>
            <div className={"container mx-auto"}>
                <div className={""}>
                    <Card className="my-4">
                        <CardHeader className="flex p-8">
                            <div>
                                <h1 className="text-4xl my-4 inline-flex gap-3 tracking-tight font-bold">
                                    <UsersThree weight="duotone"/> Manage Access
                                </h1>
                                <span className={"flex w-3/4"}>
                                    Give family and friends access to your collection of recipes. Both parties
                                    need to be registered on Cookbird in order for this feature to work.
                                </span>
                            </div>
                        </CardHeader>
                        <Divider/>
                        <CardHeader className={"flex-col items-start p-8"}>
                            <form onSubmit={submitInvite} className={"w-full"}>
                                <Input type="email"
                                       variant={"bordered"}
                                       name="email"
                                       label="Email" placeholder="Enter his/her email"
                                       className={"w-1/2 max-md:w-full"}
                                />

                                {emptyEmailError ? <span className={"text-danger block mt-1"}>
                                    Please enter a valid e-mailadress
                                </span> : ""}

                                <Button type={"submit"} color="primary" className={"mt-2"} variant="bordered">
                                    Find new friend
                                </Button>
                            </form>
                        </CardHeader>
                        <Divider/>
                        <CardBody>
                            <div
                                className="w-full  px-1 py-2 rounded-small border-default-200 dark:border-default-100">
                                <Listbox
                                    topContent={""}
                                    classNames={{
                                        base: "p-0 m-0",
                                        list: "max-h-[300px] overflow-scroll p-0 m-0",
                                    }}
                                    items={members}
                                    label="Assigned to"
                                    variant="flat"
                                    emptyContent={(isLoading ? <>
                                        <SpinnerGap className={"inline animate-spin"} />
                                        <span className={"text-sm ml-2 mt-1"}>Loading</span>
                                    </> : <>No Items</>)}
                                >
                                    {(item: AccessDto) => (
                                        <ListboxItem key={item.userIdAllowed} textValue={item.userIdAllowed}>
                                            <div className="flex gap-2 items-center">
                                                <Avatar alt={item.userIdAllowed} className="flex-shrink-0" size="sm"
                                                        src={""}/>
                                                <div className="flex flex-col">
                                                    <span className="text-small">{item.userIdAllowed}</span>
                                                    <span className="text-tiny text-default-400">{item.accepted ? "accepted" : "pending"}</span>
                                                </div>
                                            </div>
                                        </ListboxItem>
                                    )}
                                </Listbox>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Recipes;
