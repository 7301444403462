import React, { FC, useState } from 'react';
import { Button, Input } from "@nextui-org/react";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import RecipeService from "../../services/recipeService"; // Adjust the import based on your project structure
import {setRecipe} from "../../features/recipe/recipeSlice";
import useAuthToken from "../../hooks/useAuthToken";
import {useNavigate} from "react-router";

interface IFormValues {
    url: string;
    limit: string;
}

interface AddByUrlProps {
}

const AddByUrl: FC<AddByUrlProps> = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useAuthToken();

    const { register,
        handleSubmit,
        setError: setFormError,
        formState: {errors}
    } = useForm<IFormValues>();


    const onSubmit = async (data: IFormValues) => {
        const service = new RecipeService();
        setLoading(true);
        const authToken = await token.getToken();
        if (!authToken) {
            setLoading(false);
            return;
        }
        try {
            const res = await service.getRecipe(data.url, authToken);
            const jsonResponse = await res.json();
            setLoading(false);
            if (!res.ok) {
                handleErrors(jsonResponse.errors);
            } else {
                dispatch(setRecipe(jsonResponse));
                navigate("/recipe/" + jsonResponse["id"]);
            }
        } catch (error) {
            setLoading(false);
            console.error("An error occurred:", error);
        }
    };

    const handleErrors = (errors: Record<string, Array<string>>) => {
        for (const [field, errorMessage] of Object.entries(errors)) {
            const fieldName = field as keyof IFormValues;
            setFormError(fieldName, {
                type: "custom",
                message: errorMessage[0],
            });
        }
    };

    return (
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            {errors.limit ? <span className="mb-5 justify-content-start flex text-red-500 font-bold">
                {errors.limit.message}
            </span> : ""}

            <Input
                {...register('url', {required: 'URL is required'})}
                type="text"
                variant="bordered"
                label="URL"
                placeholder="https://example.com/recipes/1/chicken"
                className="w-full"
            />
            <span className={"text-sm text-red-500 font-bold mb-2"}>
                {errors.url && <p>{errors.url.message}</p>}
            </span>
            <Button
                isLoading={loading}
                color="primary"
                className="mt-2"
                variant="bordered"
                type="submit"
            >
                Fetch Recipe
            </Button>
        </form>
    );
}

export default AddByUrl;
